import logo from "../home_welcome.png";

export default function Home() {
  return (
    <div className="App">
      <main className="App-header">
        <img src={logo} className="logo" alt="logo" />
      </main> 
    </div>
  );
}
